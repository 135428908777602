<template>
  <div
              v-if="surveyData"
  >
    <v-layout wrap 
      v-for="survey of surveyData"
      :key="survey.ID"
    >
    <v-flex xs12>
      <v-layout wrap
        v-for="form of survey.Form"
        :key="form.id"
        style="background-color: #CCCCCC80; margin: 10px 0;"
      >
        <v-flex xs6 style="padding: 20px">
          <h2>
          {{getTranslation(form.question, locale)}}
          </h2>
        </v-flex>
        <v-flex xs6 v-if="form.type === 'multiSelection'">
          <p
            v-for="ms of prepareMultiSelector(form.values, form.Answers, locale)"
            :key="ms.id"
          >
          <b><small>{{ms.name}}</small></b> {{ ms.count }}
          </p>
        </v-flex>

        <v-flex xs6 v-else-if="form.type === 'singleSelection'">
          <p
            v-for="ms of prepareSingleSelector(form.values, form.Answers, locale)"
            :key="ms.id"
          >
            {{ms.name}} = {{ ms.count }}
          </p>
        </v-flex>
        <v-flex xs6 v-else-if="form.type === 'number'">
          <result-number :values="prepareNumber(form.Answers)" />
        </v-flex>
        <v-flex xs6 v-else-if="form.type === 'rating'">
          <v-layout wrap
            v-for="(r, indexR) of prepareRating(form.Answers)"
            :key="`r${indexR}`"
          >
            <v-flex xs6>
              <v-rating
                v-model="r.name"
                color="yellow darken-3"
                background-color="grey darken-1"
                empty-icon="$ratingFull"
                half-increments
                readonly
              />
            </v-flex>
            <v-flex xs6>
              {{r.count}}
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs6 v-else>
        </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import utils from '@/services/utils'
import ResultNumber from './ResultNumber'
export default {
  components: {
    ResultNumber,
  },
  props: {
    surveyData: {
      type: Array,
      default: null,
    },
    locale: {
      type: String,
      default: null
    },
  },
  data: () => ({
    rating: [0, 0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5],
  }),
  methods: {
    getTranslation (v, locale) {
      return utils.getTranslation(v, locale)
    },
    prepareSingleSelector (values, answers, locale) {
      const result = []
      for (const item of values) {
        result.push({
          id: item.id,
          name: utils.getTranslation(item.answer, locale),
          count: answers.filter(x => x.value === item.id).length
        })
      }
      return result.sort((a,b) => b.count - a.count)
    },
    prepareMultiSelector (values, answers, locale) {
      const result = []
      for (const item of values) {
        result.push({
          id: item.id,
          name: utils.getTranslation(item.answer, locale),
          count: answers.filter(x => x.value.indexOf(item.id)>=0).length
        })
      }
      return result.sort((a,b) => b.count - a.count)
    },
    prepareNumber (v) {
      if (!v || v.length === 0) return 
      const aux = v.map(item => item.value | 0)
      const sum = aux.reduce((previous, current) => current += previous)
      const avg = sum / aux.length
      const unique = [...new Set(aux)].map(item => {
        return {
          name: item,
          count: aux.filter(x => x === item).length
        }
      })
    
      return {
        avg,
        values: unique.sort((a,b) => b.count - a.count)
      }
    },
    prepareRating (v) {
      const result = []
      for (const item of this.rating) {
        result.push({
          name: item,
          count: v.filter(x => x.value === item).length
        })
      }

      //return result.filter(x => x.count>0).length > 0 ? 
      return result.sort((a,b) => a.count - b.count).reverse()
    },
    prepareResult (v) {
      if (v.type === 'rating') return this.prepareRating(v.Answers)
      return ''
    },
  },
}
</script>

