<template>
  <div>
  <v-layout wrap v-if="menus" style="padding: 20px;">
    <v-flex xs8 style="padding-right: 10px;">
      <v-autocomplete
        v-model="selectedMenuID"
        :items="menus"
        item-text="name"
        item-value="id"
        outlined
        filled
        hide-details
        :label="$t('dtouch.menu', locale)"
      />
    </v-flex>
    <v-flex xs4 style="padding-left: 10px">
      <v-dialog
        ref="dialog"
        v-model="showDate"
        :return-value.sync="date"
        persistent
        width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="date"
            :label="$t('dtouch.survey.rangeDates', locale)"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
            outlined
            hide-details
          />
        </template>
        <v-date-picker
          v-model="date"
          scrollable
          range
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="showDate = false"
          >
            {{ $t('common.cancel', locale) }}
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="handleSaveDate(date)"
          >
            {{ $t('common.ok', locale) }}
          </v-btn>
        </v-date-picker>
      </v-dialog>
    </v-flex>
    <v-flex xs12>
    </v-flex>
    <v-flex xs12
    >
      <list 
        :surveyData="surveyResult" 
        :locale="locale"
      />
    </v-flex>
  </v-layout>
  <div v-else>
    no result
  </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import api from '@/services/api'
import utils from '@/services/utils'
import List from './List'
export default {
  components: {
    List,
  },
  data: () => ({
    surveyResult: null,
    menus: null,
    selectedMenuID: null,
    showDate: false,
    date: null,
  }),
  computed: {
    ...mapState('app',['locale', 'dtouchWorkspace']),
    workspaceID () {
      return utils.getWorkspace('dtouch')
    },
  },
  watch: {
    dtouchWorkspace () {
      this.surveyResult = null,
      this.menus = null
      this.selectedMenuID = null
      this.showDate = false
      this.date = null

      this.getMenusWithSurvey()
    },
    selectedMenuID () {
      this.getResult()
    },
  },
  mounted () {
    this.getMenusWithSurvey()
  },
  methods: {
    handleSaveDate (v) {
      this.$refs.dialog.save(v)
      this.getResult()
    },
    getMenusWithSurvey () {
      api.getAllWithoutLimit ('dtouch', `v1/private/workspaces/${this.dtouchWorkspace}/survey-menus`)
        .then((response) => {
          this.menus = response.map(item => { return {
            id: item.ID,
            name: utils.getTranslation(JSON.parse(item.Name), this.locale)
          } })

          if (this.menus.length > 0) this.selectedMenuID = this.menus[0].id
        })
    },
    getResult () {
      let filter = null
      if (this.date && this.date.length === 2) filter = `&filter=${this.date.join(',')}`
      api.getAllWithoutLimit ('dtouch', `v1/private/workspaces/${this.dtouchWorkspace}/survey-result/${this.selectedMenuID}`, true, filter)
        .then((response) => {
          this.surveyResult = response
        })
    },
  },
}
</script>

