<template>
  <div>
  <v-layout wrap v-if="values">
    <v-flex xs12>
      Media = {{values.avg.toFixed(2)}}
    </v-flex>
    <v-flex xs12
      v-for="item of values.values"
      :key="item.name"
    >
      {{item.name}} = {{ item.count }}
    </v-flex>
  </v-layout>
  </div>
</template>
<script>
export default {
  props: {
    values: {
      type: Object,
      default: null,
    },
  },
}
</script>

